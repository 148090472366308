import React, {useContext, useState} from 'react';
import DataContext from "../data/context";
import {TextField} from "./input";
import SelectBox from "./input/SelectBox";
import {useMutation} from "graphql-hooks";
import {hasValue, isValidEmail} from "../validators";
import {useTranslation} from "react-i18next";
import {LoadingIndicator} from "./indicators";
import ContentPopup from "./legal/ContentPopup";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_BUTTON_ARGS } from "../tagManager";

export const resendEmailMutation =
    `mutation SendConfirmationEmail($data: SendConfirmationMailType!) { 
        sendConfirmationEmail(data: $data) {
            ok,            
        }
    }`;

const EmailConfirm = () => {
    const [errors, setErrors] = useState({});
    const {inspection, setField, dealerConfig} = useContext(DataContext);
    const [privacyAccepted, setPrivacyAccepted] = useState(false);
    const [resendMutation, {loading: resendLoading}] = useMutation(resendEmailMutation);
    const [privacyModalVisible, setPrivacyModalVisible] = useState(false);

    const {t, i18n} = useTranslation();
    const sendEmailText = t("Send new email")

    const validate = () => {
        let errors = {};

        if (!hasValue(inspection.email)) {
            errors['email'] = t("Email address required");
        } else if (!isValidEmail(inspection.email)) {
            errors['email'] = t("Invalid email address");
        }

        if (dealerConfig.formsPrivacyTickBox && !privacyAccepted) {
            errors['privacy'] = t("Please accept the privacy conditions");
        }

        setErrors(errors);

        return Object.keys(errors).length <= 0;
    };

    const handleSendAgain = () => {
        //Track buttonclick
        let aTrack = analyticstracker();
        let errorTrackingInfo = TAGMANAGER_BUTTON_ARGS({
          buttonName: 'send-email',
          buttonText: sendEmailText
        })
        let event = { "event": "button-click", "info": JSON.parse(errorTrackingInfo) };
        aTrack.trackEvent(event);
        //
        if (validate()) {
            resendMutation({
                variables: {
                    data: {
                        inspectionKey: inspection.key,
                        email: inspection.email,
                    }
                }
            }).then((result) => {
                if (result.data && result.data.SendConfirmationMailType) {
                    if (result.data.SendConfirmationMailType.ok) {

                    }
                }
            })
        }

    }


    return (
        <div className="flex flex-col justify-center w-full">
            <div className="flex flex-row justify-center pl-8 pr-8 pt-10 pb-12 md:h-64 text-color_three " style={{backgroundColor: '#EFF0F1'}}>
                <i className="po-icon po-chevron-right text-24 px-1"/>
                <div className="flex flex-col">
                    <div className="text-24 font-bold mb-2">{t("Check your mailbox")}</div>
                    <p className="leading-6 text-color_three">
                        <span>{t("We have evaluated the provided information.")} </span>

                        <span dangerouslySetInnerHTML={{
                            __html: t('Check your {{style}} inbox to see the result of your car\'s value estimation. {{styleEnd}}', {
                                style: '<span>',
                                styleEnd: '</span />',
                                interpolation: {escapeValue: false}
                            })
                        }}
                        />
                    </p>
                </div>
            </div>

            <div className="flex flex-col w-full items-center">
                <div className="flex flex-col" style={{maxWidth: 500}}>
                    <div className="flex flex-col pl-8 pr-8 pt-12 pb-10 ">

                        <div className="text-color_one text-24 font-bold mb-2">{t("Didn’t get an email?")}</div>

                        <p className="leading-6">
                            {t("Check your spam inbox in case our mails ended up there. Or we can send you a new email.")}
                        </p>

                    </div>


                    <div className="pl-8 pr-8 pb-12">
                        <TextField label={t("Your email address")}
                                   placeholder={t("john.doe@example.com")}
                                   value={inspection.email}
                                   onChange={(value) => setField('email', value)}
                                   error={errors['email']}
                        />



                        {dealerConfig.formsPrivacyTickBox &&
                        <div className="flex mt-4 mb-8 tracking-wide">
                            <SelectBox description="" selected={privacyAccepted} onChange={(value) => setPrivacyAccepted(value)}/>
                            <div className={`cursor-pointer ${errors['privacy'] ? 'text-color_error' : 'text-color_three'} text-14  ml-5`}
                                 dangerouslySetInnerHTML={{
                                     __html: t('I agree to the {{styling}} privacy terms {{stylingEnd}}', {
                                         styling: '<span class="underline cursor-pointer">',
                                         stylingEnd: '</span />',
                                         interpolation: {escapeValue: false}
                                     })
                                 }} onClick={() => {
                                    switch (i18n.language) {
                                        case 'nl': {
                                            window.open("https://www.dieteren.be/media/yjxcigfk/202101-information-notice-disa-final-nl.pdf")
                                            break
                                        }
                                        case 'fr': {
                                            window.open("https://www.dieteren.be/media/1203/1809-information-notice-disa-final-fr.pdf")
                                            break
                                        }
                                        default: {
                                            window.open("https://www.dieteren.be/media/yjxcigfk/202101-information-notice-disa-final-nl.pdf")
                                        }
                                    }
                                }}>
                            </div>
                        </div>}

                        <div className="flex md:items-center">
                            <div
                                className="btn-primary flex rounded-full bg-color_one text-white items-center font-bold text-14 cursor-pointer px-5"
                                style={{minHeight: 45}}
                                onClick={handleSendAgain}
                            >
                                {resendLoading && <div className="flex items-center">
                                    <LoadingIndicator extraSmall color="white"/>
                                    <div className="ml-2">{t('Sending mail...')}
                                    </div>
                                </div>}
                                {!resendLoading && <div>
                                        <i className="po-icon po-chevron-right mr-4 text-14"/>
                                        {sendEmailText}
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {privacyModalVisible &&
            <ContentPopup type="privacy_policy"
                          onClose={() => setPrivacyModalVisible(false)}
            />}


        </div>
    );
};

export default EmailConfirm;
