import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
import './CameraModal.css';
import {ErrorIndicator} from "../indicators";
import {useTranslation} from "react-i18next";
import {useOrientation} from "../../hooks/useOrientation";

const customStyles = {
    content: {
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'black',
        margin: 0,
        padding: 0,
        zIndex: 999999
    }
};

Modal.setAppElement('#root')

const CameraModal = ({isOpen, onOpen, onClose, onPictureTaken, pictureType, onChooseFile, hasNext=() => null, onNext, pictureTypes, overlay}) => {

    const [cameraLoaded, setCameraLoaded] = useState(false);
    const [badFormat, setBadFormat] = useState(false);
    const [cameraError, setCameraError] = useState(null);
    const [tmpImage, setTmpImage] = useState(null);
    const [tmpImageConfirmed, setTmpImageConfirmed] = useState(false);
    const [chosenPhoto, setChosenPhoto] = useState(null);
    const {t} = useTranslation();

    const orientation = useOrientation();

    const getCurrentIndex = () => {
        return pictureType !== undefined ? pictureTypes.findIndex(pt => pt.key === pictureType.key) : null;
    }

    useEffect(() => {
        if (isPortrait && tmpImage !== null && tmpImageConfirmed) {
            onClose();
            resetAll();
        }

        if (isPortrait && chosenPhoto !== null) {
            onClose();
            resetAll();
        }

    }, [orientation, chosenPhoto, tmpImageConfirmed]);


    const resetAll = () => {
        setTmpImageConfirmed(false);
        setBadFormat(false);
        setCameraError(null);
        setTmpImage(null);
        setChosenPhoto(null);
    };

    const isLandscape = orientation.isLandscape;
    const isPortrait = orientation.isPortrait;

    useEffect(() => {
        try {
            let cameraElement = document.getElementById("camera-modal");
            if (isOpen && cameraLoaded && isLandscape) {
                window.scrollTo(0, 1);
                if (!document.fullscreenElement) {
                    cameraElement.requestFullscreen().catch(err => {
                        console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
                    });
                } else {
                    // document.exitFullscreen().catch(err => {
                    //     console.log(`Error attempting exit full-screen mode: ${err.message} (${err.name})`);
                    // });
                }
            }
        } catch (e) {
            console.log(e)
        }

    }, [cameraLoaded, orientation]);

    const renderAlbumButton = () => {
        return <React.Fragment>
            <div className="relative">

                <input type='file' accept="image/*" onChange={e => {
                    const [head] = e.target.files;
                    let file = head;
                    let acceptedExtensions = ['image/jpeg', 'image/png', 'image/jpg'];
                    if (acceptedExtensions.includes(file.type)) {
                        const pictureKey = pictureType?.key || 'other'
                        setBadFormat(false);
                        onChooseFile(file, pictureKey);
                        setChosenPhoto(file);
                    } else {
                        setBadFormat(true)
                    }

                }} className="bg-green absolute top-0 bottom-0 right-0 left-0 opacity-0"/>
                <div className="flex items-center px-5 py-3">
                    <i className="fal fa-image mr-2"/> <span className="font-bold uppercase"> {t('Gallery')}</span>
                </div>
            </div>
        </React.Fragment>
    }

    const renderCameraStartError = () => {
        return <div className="flex flex-1 flex-col justify-center items-center h-screen w-screen bg-white">
            <i className="far fa-exclamation-triangle text-gray-600 mb-4" style={{fontSize: 60}}/>
            <div className="text-24 text-color_one font-bold mb-4">{t('It looks like something went wrong with your camera.')}</div>
            <div className="mb-4 text-center" style={{maxWidth: 350}}>
                {t('We noticed something went wrong with your camera. To continue your inspection, take your pictures outside the app and upload them through ‘gallery’.')}
            </div>
            <div className="flex items-center justify-center rounded-full bg-color_one text-white z-50" style={{minWidth: 350}}>
                {renderAlbumButton()}
            </div>
        </div>
    }

    const renderTmpImage = () => {
        return (
            <div className="relative flex" style={{marginLeft: 45, marginRight: 45}}>
                <img src={tmpImage} alt="Temp image" className="flex w-screen self-center " style={{height: "fit-content"}}/>
                <div className="flex w-full items-center justify-center absolute bottom-0 z-10" style={{bottom: 35}}>
                    <div className="flex rounded-full">
                        <div className="flex items-center justify-center p-4 bg-color_one text-white w-32 text-center rounded-l-full" onClick={() => {
                            
                            const picKey = pictureType !== undefined ? pictureType.key : 'other'

                            setTmpImageConfirmed(true);
                            onPictureTaken(tmpImage, picKey, true);
                        }}>
                            <i className="fal fa-check mr-3"/> OK
                        </div>
                        <div className={`flex items-center justify-center p-4 bg-color_five text-color_three w-32 text-center ${!hasNext() && "rounded-r-full"}`} onClick={() => {
                            setTmpImage(null);
                            onOpen();
                        }}>
                            <i className="fal fa-sync-alt mr-3"/> {t('Again')}
                        </div>

                        {hasNext() &&
                        <div className="flex items-center justify-center p-4 bg-color_one text-white w-32 text-center rounded-r-full"
                             onClick={() => {
                                 const picKey = pictureType !== undefined ? pictureType.key : null
                                 onPictureTaken(tmpImage, picKey, false);
                                 onNext();
                                 resetAll();
                             }}
                        >
                            <i className="fal fa-caret-right mr-3"/>OK & {t('Next')}
                        </div>}

                    </div>
                </div>
            </div>
        )
    }

    const rotateScreenToPortrait = () => {
        return <div className="bg-black text-white absolute top-0 left-0 right-0 bottom-0" style={{zIndex: 999}}>
            <div className="flex flex-1 flex-col items-center justify-center h-screen">
                <i className="icon-screen-rotate-to-portrait text-white text-24" style={{fontSize: 150}}/>
                <span className="mt-2 text-18 font-bold">{t('Rotate your smartphone')}</span>
            </div>
        </div>
    }

    const rotateScreenToLandscape = () => {
        return <div className="bg-black text-white absolute top-0 left-0 right-0 bottom-0" style={{zIndex: 999}}>
            <div className="flex flex-1 flex-col items-center justify-center h-screen">
                <i className="icon-screen-rotate text-white text-24" style={{fontSize: 150}}/>
                <span className="mt-2 text-18 font-bold">{t('Rotate your smartphone')}</span>
                <div className="flex items-center justify-center p-2 bg-color_five text-color_three w-32 text-center rounded-full mt-5 text-16 " onClick={() => onClose()}>
                    <i className="fal fa-angle-left mr-3 text-16"/>{t('Back')}
                </div>
            </div>
        </div>
    }
    return (
        <Modal
            id="camera-modal"
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customStyles}
            overlayClassName="z-50 fixed top-0 left-0 bottom-0 right-0"
            className="overflow-hidden"
        >
            {cameraError !== null && renderCameraStartError()}

            {cameraError === null && <div className="flex flex-1 justify-center items-center h-screen relative z-50">
                {isLandscape && (tmpImageConfirmed || chosenPhoto !== null) && rotateScreenToPortrait()}
                {!isLandscape && !tmpImageConfirmed && rotateScreenToLandscape()}

                <div className="flex flex-1 h-full w-full relative">
                    {!tmpImage &&
                    <div className="flex items-center justify-center absolute rounded-full bg-color_five text-gray-800 z-50" style={{top: 10, right: 10}}>
                        {renderAlbumButton()}
                    </div>}

                    {!tmpImage && (pictureType && pictureTypes?.length > 0) &&
                    <div className="flex items-center justify-center absolute z-50 p-3 text-white" style={{bottom: 35, right: 15}}>
                        {getCurrentIndex() + 1} of {pictureTypes.length}
                    </div>}
                    {!tmpImage && <div className="flex items-center justify-center absolute z-50 p-3 text-white" style={{bottom: 10, right: 15}}>
                        {pictureType && pictureType.description}
                    </div>}

                    {!tmpImage && <div className="flex items-center justify-center absolute rounded-full bg-color_five text-gray-800 z-50 p-3" style={{top: 10, left: 10}} onClick={() => onClose()}>
                        <i className="fal fa-times mr-2"/> {t('Close')}
                    </div>}

                    {tmpImage !== null && renderTmpImage()}

                    {tmpImage === null &&
                    <React.Fragment>
                        <div className="relative" style={{marginRight: 90}}>
                            <Camera isFullscreen={cameraLoaded && isLandscape}
                                    onCameraError={error => {
                                        alert(error);
                                        setCameraError(error)
                                    }}
                                    imageType={IMAGE_TYPES.JPG}
                                    onTakePhoto={(dataUri) => {
                                        setTmpImage(dataUri)
                                        // onPictureTaken(dataUri)

                                    }}
                                    onCameraStart={() => setCameraLoaded(true)}
                                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                                    idealResolution={{width: 640, height: 480}}
                                    isImageMirror={false}
                            />
                        </div>

                        {badFormat && <div className="absolute flex top-0 left-0 bottom-0 items-center justify-center z-50" style={{right: 150, left: 150}}>
                            <ErrorIndicator text={t("Wrong file type.")}/>
                        </div>}

                        {overlay &&
                        <div className="absolute flex flex-1 top-0 bottom-0 left-0 h-full items-center p-8" style={{right: 90}}>
                            <img src={overlay} alt=" Vehicle overlay" style={{height: '100%', width: "100%"}}/>
                        </div>}
                    </React.Fragment>}


                </div>

            </div>}


        </Modal>
    );
};

export default CameraModal;
