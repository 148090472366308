import React, {useContext, useEffect, useState} from 'react';
import DataContext from "../../data/context";
import {getDealerFromUrl} from "../../utils";
import LoadingIndicator from "../indicators/LoadingIndicator";
import VehicleSummary from "../vehicle/VehicleSummary";
import {useHeight} from "../../hooks/useHeight";
import {StickyElements} from "../../data/constants";
import {getTakeOverState, takeOverStates} from "./constants";
import Initial from "./status/Initial";
import Proposed from "./status/Proposed";
import Accepted from "./status/Accepted";
import Archived from "./status/Archived";
import Error from "./status/Error";
import {useTranslation} from "react-i18next";

const TakeOverStatus = ({redirect}) => {
    const {inspection, takeOver, takeOverError, takeOverFetch, loading} = useContext(DataContext);
    const [dataLoaded, setDataLoaded] = useState((redirect) ? true : inspection.id !== undefined);
    const [summaryPopUpVisible, setSummaryPopUpVisible] = useState(false);
    const topSpace = useHeight([StickyElements.header, StickyElements.currentPrice]);
    const {t} = useTranslation();

    useEffect(() => {
        if (!redirect && takeOver.state === undefined && !takeOverError) {
            takeOverFetch(inspection.key, getDealerFromUrl(window.location.pathname));
        } else {
            if (!dataLoaded) {
                setDataLoaded(true)
            }
        }
    }, [takeOver]);


    if (!dataLoaded || loading) {
        return <LoadingIndicator/>
    }

    const status = getTakeOverState(takeOver.state);
    const hasError = ((takeOver.state === undefined) && dataLoaded) || takeOverError;

    return (
        <div className="w-full md:flex md:justify-center">
            <div className="md:flex md:w-full md:px-8 lg:px-0 md:justif@y-center" style={{maxWidth: 1024}}>

                <div className="hidden md:block bg-white md:w-2/5 md:my-10">
                    <div className={`vehicle-summary-container flex flex-col bg-color_one text-white md:rounded-lg relative md:pt-10 sticky`}>
                        <VehicleSummary/>
                    </div>
                </div>

                <div className="md:w-3/5 mt-5 lg:mt-16 ml-10 mr-10">

                    <div className="sticky z-10 md:hidden mb-4" style={{top: topSpace}} onClick={() => {
                        setSummaryPopUpVisible(true)
                    }}>
                        <div className="flex items-center justify-between bg-white border-t border-b border-color_four left-0 right-0 mx-0 lg:mx-12 cursor-pointer" style={{minHeight: 40}}>
                            <div><i className="fas fa-info-circle text-color_one text-20 mr-4"/>{t('Vehicle information')}</div>
                            {/*<div><i className="fas fa-chevron-down  text-20 mr-4"/></div>*/}
                        </div>
                    </div>

                    {hasError && <Error/>}
                    {!hasError && status === takeOverStates.INITIAL && <Initial/>}
                    {!hasError && status === takeOverStates.PROPOSED && <Proposed/>}
                    {!hasError && status === takeOverStates.ACCEPTED && <Accepted/>}
                    {!hasError && status === takeOverStates.ARCHIVED && <Archived/>}
                </div>
            </div>

            {summaryPopUpVisible &&
            <div className="vehicle-summary-container absolute top-0 left-0 right-0 bottom-0 w-full h-screen z-50 bg-white text-color_one">
                <VehicleSummary onClose={() => setSummaryPopUpVisible(false)}/>
            </div>}
        </div>
    );
};

export default TakeOverStatus;
