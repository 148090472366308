import React, {useContext} from 'react';
import DataContext from "../../data/context";
import {isMobile} from "react-device-detect";

const StepHeader = () => {
    const {inspectionWizardConfiguration, inspectionWizardStep} = useContext(DataContext);
    const currentStep = inspectionWizardConfiguration[inspectionWizardStep];

    return (
            <div className="px-10 flex flex-col bg-color_five items-center pb-6 md:pb-12 md:rounded-lg lg:rounded-b-lg min-h-0 md:min-h-254">
                <i className={`${currentStep.icon} text-color_three mb-4 hidden md:block `} style={{fontSize: 70, marginTop: 30}}/>
                <span className="text-color_one text-24 font-bold mb-5 text-center hidden md:block">{currentStep.title}</span>
                <p className="leading-6">{currentStep.description}</p>
            </div>
    );
};

export default StepHeader;
