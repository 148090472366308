import React from 'react';

const TextField = ({label, value, onChange, required, placeholder, error, readOnly, type, maxLength}) => {


    return (
        <div>
            {label &&
            <label className={`block tracking-wide text-14 font-bold mb-1 text-color_one ‡tracking-widest`}>
                {label} {required ? "(*)" : ""}
            </label>}

            <input className={`appearance-none block w-full bg-white border rounded-lg py-3 px-4 focus:outline-none focus:border-primary text-14 ${(error) ? 'border-color_error' : 'border-color_two'} ${(!label) ? 'mt-5' : ''} `}
                   type={type}
                   placeholder={placeholder}
                   value={value}
                   onChange={e => onChange(e.target.value)}
                   style={{minHeight:45}}
                   readOnly={readOnly}
                   maxLength={maxLength}
            />

            {error &&
            <div className="text-color_error text-12 ml-5 mt-2"> * {error} </div>}
        </div>
    )
};

TextField.defaultProps = {
    readOnly: false,
    type: 'text'
}

export default TextField;
