import React, {useContext, useState} from 'react';
import {BrowserView, MobileView, isMobile} from "react-device-detect";
import {useDropzone} from 'react-dropzone';
import {head, find} from "lodash";
import DataContext from "../../data/context";
import LoadingIndicator from "../indicators/LoadingIndicator";
import {useTranslation} from "react-i18next";
import {getOverlay} from "./wizardsteps/Pictures";


const getHelpImage = (pictureType) => {
    try {
        return require(`../../assets/help-images/${pictureType.key}.jpg`)
    } catch (ex) {
        return undefined
    }
};


const InspectionPicture = ({picture, pictureType, error, setCameraVisible, handleFileUpload, loading}) => {
    const {deletePicture} = useContext(DataContext);
    const [helpVisible, setHelpVisible] = useState(false);
    const {t} = useTranslation();

    const {getRootProps, getInputProps} = useDropzone({
        multiple: false,
        accept: 'image/jpeg, image/png, image/jpg',
        onDrop: acceptedFiles => {
            const fileToUpload = head(acceptedFiles);
            handleFileUpload(fileToUpload, pictureType.key);
        }
    });

    const handleDeletePicture = () => {
        deletePicture(picture.key)
    };


    const handleTakePictureMobile = () => {
        // setCameraVisible(true);
        if (isMobile) {
            setCameraVisible(true);
        }
    };

    const overlay = getOverlay(pictureType);

    if (picture) {
        return (
            <div id={`pt_${pictureType.key}`} className="picture-container flex bg-color_five border  border rounded-lg relative mb-5 md:mr-5 border-color_four w-full">
                <div className="flex flex-1 cursor-pointer overflow-hidden h-full md:h-24" style={{minHeight: 208, width: "14.5rem"}}>

                    <div className="flex flex-1 flex-col items-center justify-center h-full">


                        <div className="flex flex-1 w-full items-center justify-center overflow-hidden rounded-t-lg" style={{height: 188}}>
                            <img src={picture.url} style={{width: "100%"}} className="rounded-t-lg" alt="Vehicle"/>
                        </div>

                        <div className="bg-color_five flex w-full items-center rounded-b-lg">
                            <span className="flex flex-1 text-color_one font-bold text-12 uppercase tracking-widest items-center justify-center text-center">{pictureType.description}</span>
                            <div className="p-2 bg-color_one rounded-br-lg">
                                <i className="po-icon po-trash text-20 text-white cursor-pointer self-end" onClick={handleDeletePicture}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div id={`pt_${pictureType.key}`} className={`picture-container w-full flex bg-color_five  rounded-lg relative mb-5 md:mr-5 ${error ? 'border-color_error' : 'border-color_four'}`} style={{minWidth: 0}}>

            <BrowserView viewClassName={"flex flex-1 items-center"}>
                <div className="flex flex-1 cursor-pointer overflow-hidden md:h-24 w-48" style={{minHeight: 208}}>
                    <input {...getInputProps()} />
                    <div {...getRootProps({className: 'dropzone'})} className="flex flex-1 flex-col items-center justify-center outline-none focus:outline-none">


                        <div className="flex flex-1 cursor-pointer overflow-hidden h-full md:h-24" style={{minHeight: 120}}>

                            <div className="flex flex-1 flex-col items-center justify-center h-full">

                                {loading && <div className="flex flex-1 w-full items-center justify-center"><LoadingIndicator small/></div>}
                                {!loading && <div className="flex flex-1 w-full items-center justify-center">
                                    {overlay && !helpVisible && <img src={overlay} style={{height: 100, maxWidth: "100%"}} className="rounded-t-lg" alt="Overlay"/>}

                                    {helpVisible && <div>
                                        {getHelpImage(pictureType) !== undefined ? <img src={getHelpImage(pictureType)} style={{height: 171, maxWidth: "100%"}} className="rounded-tl-lg" alt="Help"/> :
                                            <div className="flex flex-1 text-xs">{t('No photo available')}</div>}
                                    </div>}

                                </div>}

                                <div className="flex w-full items-center py-2">
                                    <span
                                        className="flex flex-1 text-color_one font-bold text-12 uppercase tracking-wider items-center justify-center text-center p-1">{pictureType.description}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserView>


            <MobileView viewClassName={"flex flex-1"}>
                <div className="flex flex-1 cursor-pointer overflow-hidden" style={{minHeight: 208}}>
                    <div className="flex flex-1" onClick={handleTakePictureMobile}>

                        <div className="flex flex-1 cursor-pointer overflow-hidden h-full md:h-24" style={{minHeight: 120}}>

                            <div className="flex flex-1 flex-col items-center justify-center h-full">

                                {loading && <div className="flex flex-1 w-full items-center justify-center" style={{minWidth: 200}}><LoadingIndicator small/></div>}
                                {!loading && <div className="flex flex-1 w-full items-center justify-center">
                                    {overlay && !helpVisible &&
                                    <div style={{padding: "8px 0"}}><img src={overlay} style={{height: 83, maxWidth: "100%"}} className="rounded-t-lg" alt="Overlay"/></div>}
                                    {helpVisible && <div>
                                        {getHelpImage(pictureType) !== undefined ? <img src={getHelpImage(pictureType)} style={{height: 187, width: "100%"}} className="rounded-tl-lg" alt="Help"/> :
                                            <div className="flex flex-1 text-xs">Geen foto beschikbaar</div>}
                                    </div>}

                                </div>}

                                <div className="flex w-full items-center">
                                    <span
                                        className="flex flex-1 text-color_one font-bold text-12 uppercase tracking-widest items-center justify-center text-center p-1">{pictureType.description}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </MobileView>


            <div className="picture-help-container flex bg-color_four items-center justify-center rounded-r-lg" style={{width: 78}}>
                <div className="flex items-center justify-center" style={{width: 40, height: 40}}
                     onMouseEnter={() => {
                         if (!isMobile) {
                             setHelpVisible(true)
                         }

                     }}
                     onMouseLeave={() => {
                         if (!isMobile) {
                             setHelpVisible(false)
                         }
                     }}
                     onClick={() => {
                         setHelpVisible(!helpVisible)
                     }}>
                         <div className="p-1 flex flex-col border-2 border-white rounded-lg  justify-center items-center" style={{borderRadius:28, width: 30, height:30}}>
                    <i className="po-icon po-info text-white cursor-pointer"
                       style={{fontSize: 17}}
                    />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InspectionPicture;
