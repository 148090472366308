import React, {useContext, useEffect} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {getDealerFromUrl} from "../utils";
import VehicleData from "./vehicle/VehicleData";
import {isMobile} from "react-device-detect";
import DataContext from "../data/context";
import QueryString from 'query-string';
import {clearVehicleStorage, inspectionFromStorage, removeInspectionFromStorage} from "../data/localstorage";
import { TAGMANAGER_PAGE_ARGS } from "../tagManager";
import analyticstracker from "analyticstracker";

const Home = () => {
    const {setInspectionPending, setData, dealerKey, dealerConfig} = useContext(DataContext);
    const location = useLocation();

    let history = useHistory();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        let aTrack = analyticstracker();
        aTrack.trackImpression("page-impression");
      }, []);

    useEffect(() => {
        const params = QueryString.parse(window.location.search);
        if (params.key) {
            const data = inspectionFromStorage(params.key)
            if (data) {
                setData(data);
            }
            history.replace({
                ...location,
                search: "",
            });
            removeInspectionFromStorage(params.key);
        } else {
            clearVehicleStorage()
        }

        setInspectionPending(true)
    }, []);

    const getBackgroundImageUrl = (dealerConfig) => {

            return `url(${window.location.origin}/branding/porsche-selfcheck.jpeg)`;
    }

    const tagInfo = TAGMANAGER_PAGE_ARGS({
        section: "inspection",
        language: i18n.language,
      });

    return (
        <div className="flex flex-1 justify-center"
             data-tracking-event="page-impression"
             data-tracking-info={tagInfo}>
            <div className="w-full">
                <div className="header-home flex flex-col bg-color_one text-white items-center justify-center relative"
                     style={{
                         height: 156,
                         background: `${getBackgroundImageUrl(dealerConfig)} no-repeat center`,
                         backgroundSize: "cover"
                     }}>

                    <div className="header-home-overlay bg-color_one absolute flex flex-1 w-full opacity-75" style={{height: 156}}/>
                   
                    <div className="absolute p-8 flex flex-row">
                        <i className="po-icon po-chevron-right" style={{fontSize: 25}}/>
                        <div style={{marginLeft: 6}}>
                            <div className="tracking-wider " style={{fontSize: 25}}>{t('Online vehicle valuation')}</div>
                            <div className="text-17 md:text-17 mt-6 " style={{fontSize: 17}}>{t('We guarantee a transparent and safe takeover.')}</div>
                        </div>
                    </div>
                </div>

                <div className="w-full md:flex md:justify-center">
                    <div className="w-full mb-12" style={{maxWidth: 780}}>
                        <VehicleData onComplete={(key) => {
                            history.push(`/${getDealerFromUrl(window.location.pathname)}/valuation/${key}`)
                        }}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
