import React from 'react';
import NumberFormat from 'react-number-format';

const NumericField = ({label, value, onChange, required, placeholder, error, suffix}) => {


    return (
        <div>
            {label &&
            <label className={`block tracking-wide text-14 font-bold mb-1 text-color_one`}>
                {label} {required ? "(*)" : ""}
            </label>}

            <NumberFormat
                className={`appearance-none block w-full bg-white border rounded-lg py-3 px-4 focus:outline-none focus:border-primary text-14 ${(error) ? 'border-color_error' : 'border-color_four'} ${(!label) ? 'mt-5' : ''} `}
                placeholder={placeholder}
                value={value}
                thousandSeparator={' '}
                allowNegative={false}
                decimalSeparator={','}
                style={{minHeight: 45}}
                onValueChange={e => {
                    onChange(e.value)
                }}
                suffix={suffix}

            />

            {error &&
            <div className="text-color_error text-12 ml-5 mt-2"> * {error} </div>}
        </div>
    )
};

export default NumericField;
