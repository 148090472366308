import React, {useContext, useState} from 'react';
import {filter, map} from "lodash";
import UploadPicture from "../UploadPicture";
import DataContext from "../../../data/context";
import {useTranslation} from "react-i18next";

const LocationDetail = ({location, onAddPictureClicked, onDeletePicture, backToOverview}) => {
    const [deletePopupVisible, setDeletePopupVisible] = useState(false);
    const [clickedDamage, setClickedDamage] = useState(null);
    const {inspection} = useContext(DataContext);
    const { t } = useTranslation();

    if(!location) {
       return <div>{t('Location not found')}</div>
    }

    const damagesForLocation = filter(inspection.damages, (d) => d.location === location.key);

    const deletePhoto = () =>  {
        const damage_id = clickedDamage.damage_id;
        const picture_url = clickedDamage.picture_url;
        onDeletePicture(damage_id, picture_url);
        setDeletePopupVisible(false)
        if(damagesForLocation.filter(dl => dl.id !== damage_id).length < 1){
            backToOverview();
        }
    }

    const handleDeleteClick = () => {
        return <div className="flex items-center justify-center fixed left-0 right-0 top-0 bottom-0 z-50" style={{background: "rgba(0, 0, 0, 0.4)"}}>
            <div className="p-5 flex flex-col border-2 border-color_one rounded-lg bg-white" style={{width: 250}}>
                <div className="text-color_one text-16 font-bold mb-5 text-center">{t('Are you sure you want to delete this picture?')}</div>
                <div className="flex items-center justify-center bg-white rounded-full border items-center font-12 text-bold text-color_three cursor-pointer border-color_four self-center" style={{width: 100}}>
                    <div className="flex  items-center justify-center h-8 w-1/2 border-r border-color_four border-color_four rounded-l-full bg-white" onClick={() => deletePhoto()}>{t('Yes')}</div>
                    <div className="flex items-center justify-center h-8 w-1/2 rounded-r-full bg-white" onClick={() => {
                        setClickedDamage(null);
                        setDeletePopupVisible(false);
                    }}>{t('No')}</div>
                </div>
            </div>
        </div>
    }

    return (
        <div>
            {deletePopupVisible && handleDeleteClick()}
            <div className="mb-5 mt-8">
                <span className="text-color_one text-24 font-bold mb-4">{location.description}</span>
            </div>

            {/*<p className="text-color_three text-14 mb-8 leading-5">*/}
            {/*    {t('This is an overview of your pictures at the selected location. Would you like to add more pictures for this location?')}*/}
            {/*</p>*/}

            <div className="flex flex-1 cursor-pointer text-14 text-color_one  mt-8 mb-8 tracking-widest font-bold" onClick={() => onAddPictureClicked(location.id)}>
                + {t('Add a picture of the damage')}
            </div>

            <div>
                {map(damagesForLocation, (damage) => {
                    return map(damage.pictures, (url) => {
                        return <UploadPicture url={url} onDeletePicture={() => {
                            setClickedDamage({damage_id: damage.id, picture_url: url});
                            setDeletePopupVisible(true);
                        } }/>
                    });
                })}
            </div>

        </div>
    );
};

export default LocationDetail;
