import React, {useContext, useState} from 'react';
import DataContext from "../../../data/context";
import {find, map} from "lodash";
import {useTranslation} from "react-i18next";

const DamagesList = ({damageLocations, onDeletePicture}) => {
    const {inspection} = useContext(DataContext);
    const [deletePopupVisible, setDeletePopupVisible] = useState(false);
    const [clickedDamage, setClickedDamage] = useState(null);
    const {t} = useTranslation();

    const deletePhoto = () => {
        const damage_id = clickedDamage.damage_id;
        const picture_url = clickedDamage.picture_url;
        onDeletePicture(damage_id, picture_url);
        setDeletePopupVisible(false);

    }

    const handleDeleteClick = () => {
        return <div className="flex items-center justify-center fixed left-0 right-0 top-0 bottom-0 z-50" style={{background: "rgba(0, 0, 0, 0.4)"}}>
            <div className="p-5 flex flex-col border-2 border-color_one rounded-lg bg-white" style={{width: 250}}>
                <div className="text-color_one text-16 font-bold mb-5 text-center">{t('Are you sure you want to delete this picture?')}</div>
                <div className="flex items-center justify-center bg-white rounded-full border items-center font-12 text-bold text-color_three cursor-pointer border-color_four self-center"
                     style={{width: 100}}>
                    <div className="flex  items-center justify-center h-8 w-1/2 border-r border-color_four border-color_four rounded-l-full bg-white" onClick={() => deletePhoto()}>{t('Yes')}</div>
                    <div className="flex items-center justify-center h-8 w-1/2 rounded-r-full bg-white" onClick={() => {
                        setClickedDamage(null);
                        setDeletePopupVisible(false);
                    }}>{t('No')}</div>
                </div>
            </div>
        </div>
    }


    return (
        <div>
            {deletePopupVisible && handleDeleteClick()}

            <div className="mb-5 mt-8">
                <span className="text-color_one text-24 font-bold mb-4">{t('Overview damages pictures')}</span>
            </div>

            {map(inspection.damages, (damage) => {
                const location = find(damageLocations, (loc) => loc.key === damage.location);
                return (
                    <div className="mb-8">
                        <div className="text-16 font-bold text-color_one mb-2"> {location && location.description}</div>

                        {map(damage.pictures, (dPic) => {
                            return (
                                <div className="relative">
                                    <div className="absolute right-0 top-0 rounded-tr-lg rounded-bl-lg p-2 bg-color_three flex items-center justify-center text-white hover:text-gray-200 cursor-pointer"
                                         onClick={() => {
                                             setClickedDamage({damage_id: damage.id, picture_url: dPic.url});
                                             setDeletePopupVisible(true)
                                         }}>
                                        <i className="po-icon po-trash text-24"/>
                                    </div>
                                    <img src={dPic} style={{width: "100%"}} className="rounded-lg mb-2" alt="Damage"/>
                                </div>
                            )

                        })}
                    </div>
                )
            })}
        </div>
    );
};

export default DamagesList;
