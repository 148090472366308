import React, {useContext} from 'react';
import DataContext from "../../data/context";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_TOOL_ARGS } from "../../tagManager";

const WizardButtons = ({validatorFunction, handlePrevious, trackingEvent=undefined, trackingInfo=undefined, trackingCommerce=undefined}) => {
    const {inspectionWizardConfiguration, inspectionWizardStep, setNextWizardStep, setPrevWizardStep} = useContext(DataContext);
    const nextStep = inspectionWizardConfiguration[inspectionWizardStep + 1];

    let history = useHistory();
    const { t } = useTranslation();

    let text = "";
    if (nextStep === undefined) {
        text = t('Final valuation')
    } else {
        text = nextStep.actionName
    }

    const handleNext = () => {
        let event

        const valid = validatorFunction();
        if (valid) {
            if (trackingEvent && trackingInfo && trackingCommerce){
                let aTrack = analyticstracker();
                event = { "event": trackingEvent, "info": JSON.parse(trackingInfo), "commerce": JSON.parse(trackingCommerce) };
                aTrack.trackEvent(event);
            }
        
            setNextWizardStep();
        }
    };

    const handlePrev = () => {
        if(handlePrevious) {
            handlePrevious(goToPrevious)
        } else {
            goToPrevious();
        }
    };

    const goToPrevious = () => {
        if(inspectionWizardStep >= 0) {
            setPrevWizardStep()
        } else {
            history.goBack()
        }
    }

    return (
        <div className="flex flex-row  justify-between items-center w-full">
            {inspectionWizardStep >= 0 && <div className="text-color_one mr-0 md:mr-10 cursor-pointer md:mt-0 uppercase" onClick={handlePrev}><i className="fal fa-chevron-left font-bold mr-2"/> {t('Previous')} </div>}
            <div className="btn-primary flex rounded-full uppercase bg-color_one text-white items-center justify-center text-center font-bold text-14 md:text-12 cursor-pointer pl-4 pr-4 tracking-wide md:w-3/5"
                 style={{minHeight: 45}}
                 onClick={handleNext}>
                <div style={{width: 20}}>
                    <i className="fal fa-chevron-right font-bold"/>
                </div>
                <div className="flex flex-1 justify-center items-center">
                    {text}
                </div>

            </div>

        </div>
    )
};

export default WizardButtons;
