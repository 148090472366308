import React, {useEffect, useState} from 'react';
import {map} from "lodash";
import {v4} from 'uuid'; 

const QualityPickerItem = ({groupKey, item, onSelect, selected, collapsed}) => {


    return (
        <div key={`${groupKey}_${item.key}`} className={`${selected ? 'bg-color_highlight' : 'bg-color_two'} border border-gray  rounded-md p-4 mb-2 cursor-pointer`} style={{minHeight: 50}} onClick={(e) => {
            e.stopPropagation()
            onSelect(item);
        }}>
            <div className="flex  justify-between">
                <div className="flex">
                    <input type="radio"
                           id={`${groupKey}_${item.key}`}
                           name={groupKey}
                           checked={selected}
                           className="mr-4"
                           onChange={(e) => {
                               e.stopPropagation()
                           }}/>
                    <label htmlFor={`${groupKey}_${item.key}`} className={`${selected ? 'text-color_five' : 'text-color_five'} text-17 font-bold tracking-widest`}>{item.name}</label>
                </div>

                <div className="flex flex-end">
                    <i className={`${(collapsed) ? 'fal fa-chevron-down' : 'fal fa-chevron-up' } ${selected ? 'text-color_five' : 'text-color_five'} text-14  font-bold`}/>
                </div>
            </div>

            {!collapsed &&
            <p className={`${selected ? 'text-color_five' : 'text-color_five'} text-14 mt-3 leading-5`} style={{marginLeft: 40, marginRight: 20}}>{item.description}</p>}
        </div>
    )
};

const QualityPicker = ({value, items, onSelect, error}) => {

    const groupKey = v4();

    return (
        <React.Fragment>

            {map(items, (item, i) => {
                return (
                    <QualityPickerItem groupKey={groupKey}
                                       item={item}
                                       onSelect={onSelect}
                                       selected={item.key === value}
                                       collapsed={!((item.key === "good" && value === undefined) || (item.key === value))}
                                       key={`quality_${i}`}
                    />


                )
            })}

            {error &&
            <div className="text-color_error text-12 ml-5 mt-2"> * {error} </div>}
        </React.Fragment>
    );
};

export default QualityPicker;
