import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../data/context";
import { useTranslation } from "react-i18next";
import { keys } from "lodash";
import WizardButtons from "../WizardButtons";
import { TextField } from "../../input";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";
import WizardError from "../WizardError";
import NumericField from "../../input/NumericField";

const VehicleIdentification = () => {
  const { inspection, setField } = useContext(DataContext);
  const [showHelp, setShowHelp] = useState(false);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const LICENSEPLATE_MAXLENGTH = 10;

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate();
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "identification",
    toolStepNumber: "9",
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({});

  const validate = () => {
    let errors = {};
    const trackError = (error) => {
      let aTrack = analyticstracker();
      let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
        event: "error",
        toolStep: "identification",
        toolStepNumber: "9",
        errorType: "user-error",
        errorMessage: error,
      });
      let event = {
        event: "tool-error",
        info: JSON.parse(errorTrackingInfo),
        commerce: JSON.parse(tagArgsCommerce),
      };
      aTrack.trackEvent(event);
    };

    if (!inspection.licensePlate && !inspection.vin) {
      errors["vehicleIdentification"] = t(
        "Please fill out the car's license plate or vin number"
      );
      trackError(errors.vehicleIdentification);
    }

    if (
      inspection.licensePlate &&
      inspection.licensePlate.trim().length === 0
    ) {
      errors["vehicleIdentification"] = t("Invalid license plate.");
      trackError(errors.vehicleIdentification);
    }
    if (
      inspection.licensePlate &&
      inspection.licensePlate.trim().length > LICENSEPLATE_MAXLENGTH
    ) {
      //This is also enforced my maxLength but you never know
      errors["vehicleIdentification"] = t("Invalid license plate.");
      trackError(errors.vehicleIdentification);
    }
    if (inspection.vin && inspection.vin.trim().length !== 17) {
      errors["vehicleIdentification"] = t(
        "The vin number must be 17 characters long."
      );
      trackError(errors.vehicleIdentification);
    }
    if (!inspection.mileage) {
      errors['mileage'] = t("Please select the mileage of your car");
    }
    setErrors(errors);

    return keys(errors).length <= 0;
  };

  const validator = () => {
    return validate();
  };

  return (
    <div
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      <div className="text-color_one text-24 font-bold mb-4 mt-10">
        {t("Vehicle identification")}
      </div>

      <div className="text-16 leading-6 mb-4 mt-6">
        {t(
          "To identify the type and characteristics of your vehicle, please fill in the registration or the chassis number"
        )}
      </div>

      <div className="flex justify-center mb-10">
        <div className="w-full">
          <TextField
            placeholder="1-ABC-123"
            // placeholder={t("License plate")}
            label={t("License plate")}
            value={inspection.licensePlate}
            onChange={(value) => setField("licensePlate", value)}
            // error={errors['licensePlate']}
            maxLength={LICENSEPLATE_MAXLENGTH}
          />
        </div>
      </div>

      <div
        className="w-full border-b border-color_five text-center mb-5 mt-5"
        style={{ height: 10 }}
      >
        <span
          className="text-10 pl-4 pr-4 bg-white"
          style={{ color: "#B9BED1" }}
        >
          {t("or")}
        </span>
      </div>

      <div className="flex justify-center mb-10 relative">
        <div className="w-full">
          <TextField
            placeholder={"WP0ZZZ95ZJN100108"}
            label={t("Chassisnumber")}
            value={inspection.vin}
            onChange={(value) => setField("vin", value)}
            maxLength="17"
            // error={errors['vin']}
          />
        </div>
        <div className="pt-5 ml-1">
          <i
            className="fal fa-question-circle mt-4 cursor-pointer"
            onMouseEnter={() => setShowHelp(true)}
            onMouseLeave={() => setShowHelp(false)}
            onClick={() => setShowHelp(!showHelp)}
          />
        </div>
        {showHelp && (
          <div
            className="absolute mt-2 border border-gray-400 shadow-2xl bg-white p-1 rounded z-50"
            style={{ top: "100%" }}
          >
            <img
              src={require("../../../assets/help-images/vin.jpg")}
              alt="Chassisnumber"
            />
          </div>
        )}
      </div>

      <div className="flex items-center justify-center mb-2">
        <div className="text-color_error text-12">
          {" "}
          {errors["vehicleIdentification"]}
        </div>
      </div>

      <div data-tracking-event="tool-start" data-tracking-info={tagArgsStart} data-tracking-commerce={tagArgsCommerce}>
            <div className="text-color_one text-24 font-bold mb-4 mt-10">{t('Mileage')}</div>


            <div className="text-16 leading-6 mb-4 mt-6">{t('How many kilometers does your vehicle have?')}</div>

            <div className="flex mb-10 justify-center">
                <div className="w-full">
                    <NumericField value={inspection.mileage}
                                  onChange={(value) => 
                                      setField('mileage', value)}
                                  suffix=" km"
                                  placeholder='Km'
                                  label={t('Aantal kilometers')}
                                  error={errors['mileage']}
                    />
                </div>
                {/*<div className="ml-2 mt-5 flex items-center">km</div>*/}
            </div>

            <WizardError errors={errors}/>

        </div>

      <WizardButtons
        validatorFunction={validator}
        trackingEvent="tool-submit"
        trackingInfo={TAGMANAGER_TOOL_ARGS({
          event: "submit",
          toolStep: "identification",
          toolStepNumber: "9",
        })}
      />
    </div>
  );
};

export default VehicleIdentification;
