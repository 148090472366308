import React, {useEffect} from 'react';
import StatusGeneral from "../components/StatusGeneral";
import {useTranslation} from "react-i18next";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_PAGE_ARGS } from "../../../tagManager";

const Initial = () => {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        analyticstracker().trackImpression("page-impression");
      }, []);

    const tagInfo = TAGMANAGER_PAGE_ARGS({
        pageName: "car-quotation/valuation-2",
        section: "valuation-2",
        language: i18n.language,
    });

    return (
        <React.Fragment>
            <StatusGeneral title={t("Your request has been submitted")}/>

            <div className="md:w-3/4"
                         data-tracking-event="page-impression"
                         data-tracking-info={tagInfo}>
                <div className="flex bg-color_four rounded-lg p-6">
                    <div className="flex items-center justify-center"><i className="fal fa-network-wired mr-8 text-24 text-white"/></div>
                    <div className="font-bold text-white text-center">{t("We'll contact all traders in our network to be able to offer you the best price. ")}</div>
                </div>

                <div className="text-color_three mb-8 mt-8 leading-6">
                    <p className="leading-6"
                       dangerouslySetInnerHTML={{
                           __html: t("Please provide {{styling}} 3 working days {{stylingEnd}}.", {
                               styling: '<span class="font-bold text-color_one">',
                               stylingEnd: '</span />',
                               interpolation: {escapeValue: false}
                           })
                       }}>
                    </p>

                    <p>{t("You can always follow the progress of the application here on this page.")}</p>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Initial;
