import React, {useContext} from 'react';
import DataContext from "../../data/context";

const StepIndicator = () => {
    const {inspection, inspectionWizardConfiguration, inspectionWizardStep} = useContext(DataContext);

    return (
        <div className="flex items-center justify-center left-0 bg-color_five right-0 mx-0 lg:mx-12" style={{minHeight: 70}}>
            <div className="flex justify-between items-center px-10 relative w-auto">
                <div className="border border-t-1 border-color_four flex mx-10 absolute left-0 right-0 " style={{transform: "translateY(-50%)", top: "50%"}}/>
                <div className="flex w-full items-center justify-between md:justify-center">
                    {inspectionWizardConfiguration.map((step, index) => {
                        const badgeCount = (step.badgeCounter) ? step.badgeCounter(inspection) : undefined
                        return (
                            <div className="relative" key={`step_${index}`}>
                                <i key={index}
                                   className={`${step.icon} border-2 z-10 rounded-full p-2 bg-white ${(index === inspectionWizardStep) ? 'text-color_one border-color_one' : 'text-color_four border-color_four'} ${(index < inspectionWizardConfiguration.length - 1) ? 'mr-6 md:mr-4 lg:mr-8' : ''}`}
                                   style={{fontSize: 22, borderRadius: 20}}/>
                                {badgeCount !== undefined && badgeCount > 0 && <div className="absolute top-0 p-1 text-white rounded-full bg-color_one flex items-center justify-center w-5 h-5" style={{left: 35, top: "50%", transform: "translateY(-50%)", borderRadius: 20}}>{badgeCount}</div>}
                            </div>
                        )
                    })}
                </div>
            </div>


        </div>
    );
};

export default StepIndicator;
